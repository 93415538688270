import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { CellListState } from "../cells/slice";
import { cellsSlices } from "../cells/slice";
import type { RootState } from "../../store";
import type { AuthState } from "../../store/auth/slice";

import { getSingleExperiment } from "./singleSlice";

import ButtonLogEvent from "../../components/table/ButtonLogEvent";
import ReadyOffMenu from "../cells/ReadyOffMenu";
import TestModal from "../metadata/layout/TestModal";
import CreateSamplesButton from "../../components/CreateSamplesButton";
import { FSC_BATTERY_MODULE } from "../../utils/labels";

const ExpCellOptions = ({ exp_id }: { exp_id: string }) => {
  const stateKey = "EXPERIMENT";

  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);

  const { cells, selected } = useSelector<RootState, CellListState>(
    (state) => state[`cellList_${stateKey}` as keyof RootState] as CellListState
  );
  const selectedCells = cells.filter((cell) => selected.includes(cell.cell_id));

  const [testModalOpen, setTestModalOpen] = useState(false);
  const testModal = testModalOpen ? (
    <TestModal
      open={testModalOpen}
      onClose={() => {
        setTestModalOpen(false);
        dispatch(getSingleExperiment(parseInt(exp_id)));
      }}
      selectedCells={selectedCells}
      reserved_channel={
        selectedCells.find((cell) => !!cell.reserved_channel.channel.fullname)
          ?.reserved_channel.channel.fullname || ""
      }
      executor={user}
      conditionIdsToCellIdsForMetadataFetch={selectedCells.reduce(
        (
          conditionIdsToRequestedCellIds: { [key: string]: string[] },
          selectedCell
        ) => {
          const cellConditionId = selectedCell.condition.cell_condition_id;
          if (!cellConditionId) {
            return conditionIdsToRequestedCellIds;
          }
          if (
            !Object.keys(conditionIdsToRequestedCellIds).includes(
              String(cellConditionId)
            )
          ) {
            conditionIdsToRequestedCellIds[cellConditionId] = [];
          }
          conditionIdsToRequestedCellIds[cellConditionId] = [
            ...conditionIdsToRequestedCellIds[cellConditionId],
            String(selectedCell.cell_id),
          ];
          return conditionIdsToRequestedCellIds;
        },
        {}
      )}
    />
  ) : null;

  const dispatch = useDispatch();

  return (
    <>
      {selected.length > 0 && (
        <ButtonLogEvent itemType={"cell"} objects={selectedCells} />
      )}
      {selected.length > 0 && (
        <CreateSamplesButton
          from={`/experiments/${exp_id}`}
          cell_ids={selected.map((sel) => `${sel}`)}
        />
      )}

      <ReadyOffMenu
        showAddModule={true}
        exp_id={exp_id}
        disablePutOn={
          selectedCells.filter(
            (cell) =>
              !cell.cell_type.allow_batch_on_test ||
              cell.cell_type.cell_assembly === FSC_BATTERY_MODULE ||
              !["L", "F"].includes(cell.status) ||
              cell.backfill ||
              (selected[0] &&
                cell.condition.cell_assembly !==
                  cells.find((_cell) => _cell.cell_id === selected[0])
                    ?.condition.cell_assembly)
          ).length > 0
        }
        onPutOn={() => setTestModalOpen(true)}
        showTakeOff={selectedCells.filter((cell) => cell.backfill).length > 0}
        disableTakeOff={
          (selectedCells.filter((cell) => cell.backfill).length > 0 &&
            selectedCells.filter((cell) => !cell.backfill).length > 0) ||
          selectedCells.filter((cell) => !["P", "N"].includes(cell.status))
            .length > 0
        }
        onTakeOff={() =>
          dispatch(
            cellsSlices[stateKey].takeOff({
              cells: selectedCells,
              user_id: user?.user_id,
            })
          )
        }
        selected={selectedCells.sort((a, b) =>
          a.cell_id > b.cell_id ? 1 : -1
        )}
        onReadyOff={() =>
          dispatch(
            cellsSlices[stateKey].readyOff({
              cells: selectedCells,
              cancel: false,
              user_id: user?.user_id,
            })
          )
        }
        onCancelReadyOff={() =>
          dispatch(
            cellsSlices[stateKey].readyOff({
              cells: selectedCells,
              cancel: true,
              user_id: user?.user_id,
            })
          )
        }
        onDiscontinue={() =>
          dispatch(
            cellsSlices[stateKey].discontinueCells({
              cells: selectedCells,
              cancel: false,
            })
          )
        }
        onCancelDiscontinue={() =>
          dispatch(
            cellsSlices[stateKey].discontinueCells({
              cells: selectedCells,
              cancel: true,
            })
          )
        }
        multipleCells={selectedCells.length > 1}
      />
      {testModal}
    </>
  );
};

export default ExpCellOptions;
