import React, { useState } from "react";
import AddCellsToModule from "../modules/AddCellsToModule";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import NavigationMenuHorizontalIcon from "../../icons/NavigationMenuHorizontal";
import ReservationMenu from "./ReservationMenu";
import { cellsSlices } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import { showTestStandOptions } from "../../utils/testStandRules";
import { resetUpdateTeardownFlags } from "./modifyTeardownSpecsSlice";
import ModifyTeardownSpecsModal from "./ModifyTeardownSpecsModal";
import { FSC_BATTERY_MODULE } from "../../utils/labels";
import { useNavigate } from "react-router-dom";
import { ALLOWED_CELL_ASSEMBLIES_FOR_EDIT } from "../batch-external-data-entry";
import { uniq } from "lodash";
import { RootState } from "../../store";
import {
  FeatureFlagsState,
  LAUNCH_BATCH_CONDITION_SPECIFICATION,
} from "../../store/featureFlags/slice";
import OffTestConfirmationModal from "./OffTestConfirmationModal";

type Props = {
  stateKey?: keyof typeof cellsSlices | null;
  large?: boolean;
  showTakeOff?: boolean;
  // A list of Cell.STATUS_* letters. Typically a CellList's list of cell statuses
  // to filter for.
  presetStatus?: CellStatus[];
  disablePutOn?: boolean;
  showAddReserveChannels?: boolean;
  showAddModule?: boolean;
  showRemoveFromModule?: boolean;
  exp_id?: string;
  overrideOnDisableMessage?: string | null;
  disableTakeOff: boolean;
  selected: (Cell | CellSingle)[];
  onEditCondition?: () => void;
  onPutOn?: () => void;
  onTakeOff: () => void;
  onReadyOff: () => void;
  onCancelReadyOff: () => void;
  onDiscontinue: () => void;
  onCancelDiscontinue: () => void;
  onRemoveFromModule?: () => void;
  multipleCells?: boolean;
};

/* The three-dots overflow menu shown on the cell list tabs and elsewhere. */
const ReadyOffMenu = ({
  stateKey = null,
  large = true,
  showTakeOff = false,
  presetStatus = [],
  disablePutOn = true,
  showAddReserveChannels = false,
  showAddModule = false,
  showRemoveFromModule = false,
  multipleCells = false,
  overrideOnDisableMessage,
  disableTakeOff,
  exp_id,
  selected,
  onEditCondition,
  onPutOn,
  onTakeOff,
  onReadyOff,
  onCancelReadyOff,
  onDiscontinue,
  onCancelDiscontinue,
  onRemoveFromModule,
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enabledFlags } = useSelector<RootState, FeatureFlagsState>(
    ({ featureFlags }) => featureFlags
  );
  const [modifyTeardownSpecsOpen, setModifyTeardownSpecsOpen] = useState(false);
  const [markReadyOffAfterSpecReview, setMarkReadyOffAfterSpecReview] =
    useState(false);
  const [buttonEl, setButtonEl] = useState<
    null | HTMLButtonElement | HTMLAnchorElement
  >(null);
  const [confirmCellsOffTestModalOpen, setConfirmCellsOffTestModalOpen] =
    useState(false);

  const handleButtonClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    event.stopPropagation();
    setButtonEl(event.currentTarget);
    if (stateKey) {
      dispatch(cellsSlices[stateKey].resetTestStandReservation());
      dispatch(resetUpdateTeardownFlags());
    }
  };

  const handleButtonClose = (
    event: React.MouseEvent<
      HTMLLIElement | HTMLButtonElement | HTMLAnchorElement
    >
  ) => {
    event.stopPropagation();
    setButtonEl(null);
  };

  const editDisabled = selected.some((cell) => cell.backfill === 1);
  const batchSpecificationEditDisabled = selected.some(
    (cell) =>
      cell.backfill ||
      cell.condition.cell_assembly !== selected[0].condition.cell_assembly
  );

  const flagDisabled = selected.some(
    (cell) =>
      cell.status !== "N" ||
      !cell.test_meta__test_meta_id ||
      !!cell.ready_off.completed_at
  );

  const unflagDisabled = selected.some(
    (cell) =>
      cell.status !== "N" ||
      !cell.test_meta__test_meta_id ||
      !cell.ready_off.completed_at
  );

  const discontinueDisabled = selected.some((cell) => cell.status === "U");

  const undiscontinueDisabled = selected.some((cell) => cell.status !== "U");

  const [addModuleModalOpen, setAddModuleModalOpen] = useState<boolean>(false);
  const addModuleDisabled = !selected
    .flatMap((cell) => cell.condition.cell_assembly)
    .every((val, i, arr) => val === arr[0]);

  const label =
    selected.length === 1
      ? "This cell"
      : "Invalid selection. The selected cells";

  const onTestDisabledMessage =
    showRemoveFromModule === true
      ? "All cells in a module must be put on-test at the same time, and have the status “Fill Complete” or ”Off Test”"
      : `${label} must ${
          selected.length !== 1 ? "all" : ""
        } be of an assembly that allows batch on-test, have the status “Fill Complete” or ”Off Test”, and cannot contain backfilled or "${FSC_BATTERY_MODULE}" cells`;

  const offTestDisabledMessage = `${label} must ${
    selected.length !== 1 ? "all" : ""
  } have the status “On test”, be marked as “Complete”, and cannot contain backfilled cells`;
  const offTestWarningMessage =
    "Once cells are taken off test, changes to teardown flags require approval.";
  const showBlockTeardownChangeExplanation =
    selected.length > 0 &&
    selected.some((cell) => ["F", "O", "U"].includes(cell.status));

  const showBatchMetadataInputOption =
    (["C", "N"].some((cellStatus) =>
      presetStatus.includes(cellStatus as CellStatus)
    ) ||
      !!exp_id) &&
    selected.every(
      (selectedCell_) => !["I", "U"].includes(selectedCell_.status)
    );

  // presetStatus will only be populated if ReadyOffMenu is called from the menu bar
  const fromMenuBar = !(presetStatus.length === 0);

  return (
    <>
      <IconButton
        size="small"
        disabled={selected.length === 0}
        onClick={handleButtonClick}
      >
        <NavigationMenuHorizontalIcon
          style={!large ? { width: 20, height: 20 } : undefined}
        />
      </IconButton>

      <Menu
        anchorEl={buttonEl}
        open={!!buttonEl}
        onClose={handleButtonClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {!!exp_id &&
          enabledFlags?.includes(LAUNCH_BATCH_CONDITION_SPECIFICATION) && (
            <Tooltip
              arrow
              placement="left"
              title="Selected cells must have the same assemblies in order to batch view/edit."
              disableHoverListener={!batchSpecificationEditDisabled}
              disableFocusListener={!batchSpecificationEditDisabled}
            >
              <div>
                <MenuItem
                  disabled={batchSpecificationEditDisabled}
                  onClick={(e) => {
                    handleButtonClose(e);
                    navigate(
                      `/experiments/${exp_id}/batch-conditions/${uniq(
                        selected.map(
                          (selectedCell) =>
                            selectedCell.condition.cell_condition_id!
                        )
                      ).join(",")}`
                    );
                  }}
                >
                  View/edit cell specifications
                </MenuItem>
              </div>
            </Tooltip>
          )}

        {!fromMenuBar ||
        presetStatus.includes("N") ||
        presetStatus.includes("L") ? (
          <div>
            {onEditCondition ? (
              <Tooltip
                arrow
                placement="left"
                title={`${label} ${
                  selected.length !== 1 ? "are all" : "is"
                } backfilled from TrackerWare and cannot be edited or duplicated.`}
                disableHoverListener={!editDisabled}
                disableFocusListener={!editDisabled}
              >
                <div>
                  <MenuItem
                    disabled={editDisabled}
                    onClick={(e) => {
                      handleButtonClose(e);
                      onEditCondition();
                    }}
                  >
                    Edit condition specification
                  </MenuItem>
                </div>
              </Tooltip>
            ) : null}

            <Tooltip
              arrow
              placement="left"
              title={`${label} must ${
                selected.length !== 1 ? "all" : ""
              } have the status “On test” to complete this action.`}
              disableHoverListener={!flagDisabled}
              disableFocusListener={!flagDisabled}
            >
              <div>
                <MenuItem
                  disabled={flagDisabled}
                  onClick={(e) => {
                    handleButtonClose(e);
                    if (
                      selected.some((cell) => !cell.condition.bypass_commit)
                    ) {
                      setMarkReadyOffAfterSpecReview(true);
                      setModifyTeardownSpecsOpen(true);
                    } else {
                      onReadyOff();
                    }
                  }}
                >
                  Flag as ready to take off test
                </MenuItem>
              </div>
            </Tooltip>

            <Tooltip
              arrow
              placement="left"
              title={`${label} must ${
                selected.length !== 1 ? "all" : ""
              } have the status “On test” and the “Ready Off” flag to complete this action.`}
              disableHoverListener={!unflagDisabled}
              disableFocusListener={!unflagDisabled}
            >
              <div>
                <MenuItem
                  disabled={unflagDisabled}
                  onClick={(e) => {
                    handleButtonClose(e);
                    onCancelReadyOff();
                  }}
                >
                  Remove flag "Ready to take off test"
                </MenuItem>
              </div>
            </Tooltip>

            <Tooltip
              arrow
              placement="left"
              title={overrideOnDisableMessage || onTestDisabledMessage}
              disableHoverListener={!disablePutOn}
              disableFocusListener={!disablePutOn}
            >
              <div>
                <MenuItem
                  disabled={disablePutOn}
                  onClick={(e) => {
                    handleButtonClose(e);
                    onPutOn && onPutOn();
                  }}
                >
                  Put on test
                </MenuItem>
              </div>
            </Tooltip>

            <Tooltip
              arrow
              placement="left"
              title={
                disableTakeOff ? offTestDisabledMessage : offTestWarningMessage
              }
            >
              <div>
                <MenuItem
                  disabled={disableTakeOff}
                  onClick={(e) => {
                    handleButtonClose(e);
                    setConfirmCellsOffTestModalOpen(true);
                  }}
                >
                  Take off test
                </MenuItem>
              </div>
            </Tooltip>

            <Tooltip
              arrow
              placement="left"
              title={`${label} must not have the status “Discontinued” to complete this action.`}
              disableHoverListener={!discontinueDisabled}
              disableFocusListener={!discontinueDisabled}
            >
              <div>
                <MenuItem
                  disabled={discontinueDisabled}
                  onClick={(e) => {
                    handleButtonClose(e);
                    onDiscontinue();
                  }}
                >
                  Mark as discontinued
                </MenuItem>
              </div>
            </Tooltip>

            <Tooltip
              arrow
              placement="left"
              title={`${label} must ${
                selected.length !== 1 ? "all" : ""
              } have the status “Discontinued” to complete this action.`}
              disableHoverListener={!undiscontinueDisabled}
              disableFocusListener={!undiscontinueDisabled}
            >
              <div>
                <MenuItem
                  disabled={undiscontinueDisabled}
                  onClick={(e) => {
                    handleButtonClose(e);
                    onCancelDiscontinue();
                  }}
                >
                  Unmark as discontinued
                </MenuItem>
              </div>
            </Tooltip>
          </div>
        ) : null}

        {showAddReserveChannels &&
          stateKey &&
          presetStatus.some((statusLetter) =>
            /* Cells can get their first channel reservation if they're in
               Cell.STATUS_SPECIFIED/S, STATUS_STAGED/T, and STATUS_COMMITTED/C, of they can get
               a channel-change reservation if they're in
               STATUS_ON_TEST_PREFLIGHT/P or STATUS_ON_TEST/N.
             */
            ["S", "T", "C", "P", "N"].includes(statusLetter)
          ) && (
            <ReservationMenu
              stateKey={stateKey}
              selected={selected as Cell[]}
            />
          )}

        <Tooltip
          arrow
          placement="left"
          title={`Some selected cell(s) have statuses that won't allow changes to teardown specs.`}
          disableHoverListener={!showBlockTeardownChangeExplanation}
          disableFocusListener={!showBlockTeardownChangeExplanation}
        >
          <div>
            <MenuItem
              disabled={
                selected.length === 0 || showBlockTeardownChangeExplanation
              }
              onClick={(e) => {
                handleButtonClose(e);
                setModifyTeardownSpecsOpen(true);
              }}
            >
              Modify teardown specifications
            </MenuItem>
          </div>
        </Tooltip>

        {showBatchMetadataInputOption && (
          <MenuItem
            onClick={(e) => {
              navigate(
                `/metadata/batch-external-data-entry/${selected
                  .map((selectedCell_) => selectedCell_.cell_id)
                  .join(",")}`,
                {
                  state: {
                    from: !!exp_id
                      ? `/experiments/${exp_id}`
                      : presetStatus.includes("N")
                      ? "/cells/testing"
                      : "/cells/committed",
                  },
                }
              );
            }}
          >
            {`${
              selected.every(
                (selectedCell) =>
                  ALLOWED_CELL_ASSEMBLIES_FOR_EDIT.includes(
                    selectedCell.cell_type.cell_assembly
                  ) &&
                  selectedCell.cell_type.cell_assembly ===
                    selected[0].cell_type.cell_assembly
              )
                ? "Update"
                : "View"
            } serial number metadata for cells`}
          </MenuItem>
        )}

        {showAddModule && exp_id && (
          <Tooltip
            arrow
            placement="left"
            title={`${label} must all have the same cell assembly type.`}
            disableHoverListener={!addModuleDisabled}
            disableFocusListener={!addModuleDisabled}
          >
            <div>
              <MenuItem
                disabled={addModuleDisabled}
                onClick={(e) => {
                  handleButtonClose(e);
                  setAddModuleModalOpen(true);
                }}
              >
                Add to module
              </MenuItem>
            </div>
          </Tooltip>
        )}

        {showRemoveFromModule && onRemoveFromModule && (
          <div>
            <MenuItem
              disabled={false}
              onClick={(e) => {
                handleButtonClose(e);
                onRemoveFromModule();
              }}
            >
              Remove from module
            </MenuItem>
          </div>
        )}

        {selected.every(({ module, cell_type: { reserve_test_stands } }) => {
          const modId = module ? module.module_id : undefined;
          return showTestStandOptions({
            reserve_test_stands,
            module_id: modId,
          });
        }) && (
          <div>
            <MenuItem
              onClick={(e) => {
                dispatch(
                  cellsSlices[stateKey || "ALL"].toggleTestStandsForCells({
                    cells: selected,
                  })
                );
                handleButtonClose(e);
              }}
            >
              {selected.some(
                ({
                  module,
                  cell_type: { reserve_test_stands },
                  condition: { override_test_stands },
                }) => {
                  const modId = module ? module.module_id : undefined;
                  return showTestStandOptions({
                    reserve_test_stands,
                    module_id: modId,
                    override_test_stands,
                  });
                }
              )
                ? "Use Channel Reservation / On-test"
                : "Use Test Stand Reservation / On-test"}
            </MenuItem>
          </div>
        )}
      </Menu>

      {showAddModule && exp_id && (
        <AddCellsToModule
          selected={selected as Cell[]}
          exp_id={exp_id}
          modalOpen={addModuleModalOpen}
          onClose={() => setAddModuleModalOpen(false)}
        />
      )}
      <ModifyTeardownSpecsModal
        open={modifyTeardownSpecsOpen}
        onClose={() => {
          setMarkReadyOffAfterSpecReview(false);
          setModifyTeardownSpecsOpen(false);
        }}
        selected={selected as Cell[]}
        onReadyOff={markReadyOffAfterSpecReview ? onReadyOff : undefined}
      />

      <OffTestConfirmationModal
        open={confirmCellsOffTestModalOpen}
        onClose={() => setConfirmCellsOffTestModalOpen(false)}
        multipleCells={multipleCells}
        isLoading={false}
        onConfirm={() => {
          setConfirmCellsOffTestModalOpen(false);
          onTakeOff();
        }}
      />
    </>
  );
};

export default ReadyOffMenu;
