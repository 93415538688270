import { Box, CircularProgress, Typography } from "@mui/material";
import Button from "../../components/Button";
import Modal from "../../components/Modal";

type Props = {
  open: boolean;
  onClose: any;
  isLoading: boolean;
  multipleCells?: boolean;
  onConfirm: any;
};

/**
 * This modal reminds users to confirm that cells are physically removed from a channel before marking them off-test.
 * Shown after selecting the "Take off test" menu option, for example from the cell details three-dot menu or the Testing+ tab three-dot menu.
 */
const OffTestConfirmationModal = ({
  open,
  onClose,
  isLoading,
  multipleCells = false,
  onConfirm,
}: Props) => (
  <Modal open={open} onClose={onClose}>
    <>
      <Typography variant="h2" textAlign="center">
        {`Confirm that ${
          multipleCells ? "these cells have" : "this cell has"
        } been physically removed from the channel`}
      </Typography>
      <Box mt={8} mb={2}>
        <Typography color="textSecondary" textAlign="center">
          If you would like Tech Ops to remove the cell for you, please use the
          ready off test option instead.
        </Typography>
      </Box>
      <Box mt={8} display="flex" justifyContent="center">
        <Box mr={1}>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Box>
        <Button
          color="primary"
          onClick={onConfirm}
          endIcon={
            isLoading ? <CircularProgress color="inherit" size={20} /> : null
          }
          disabled={isLoading}
        >
          {multipleCells ? "Cells have" : "Cell has"} been removed from the
          channel{multipleCells ? "s" : ""}
        </Button>
      </Box>
    </>
  </Modal>
);

export default OffTestConfirmationModal;
